import * as Xdag from "../../components/custom/xdag"
import dcr from "../../assets/cards/DCR.png";


export const needCustomDashboard = [
    {
        coin: "xdag",
        dashboard: Xdag.XdagDashboard,
        minerPage: Xdag.XdagMiner
    }
]

export const customCards = [
    {
        "type": "custom",
        "coin": "DCR",
        "name": "Decred",
        "logo": dcr,
        "link": "https://dcr.threepool.tech/"
    }
]
