/* import {
  getFoundationV1Pools,
  getFoundationV1Cards,
  getFoundationV1DashboardData,
  getFoundationV1MinerData,
} from "./foundationV1";
*/
 import {
  getFoundationV2Cards,
  getFoundationV2DashboardData,
  getFoundationV2MinerData,
  getFoundationV2Pools,
} from "./foundationV2"; 

import {
  getMiningCoreCards,
  getMiningCoreDashboardData,
  getMiningCoreMinerData,
  getMiningCorePools,
} from "./miningCore";

import {
  getMiningCoreCardsKas,
  getMiningCoreDashboardDataKas,
  getMiningCoreMinerDataKas,
  getMiningCorePoolsKas,
} from "./miningCoreKaspa";
import {
  getXdagCards,
  getXdagDashboardData,
  getXdagMinerData
} from "./xdag";

import {
  getMiningCoreCardsTls,
  getMiningCoreDashboardDataTls,
  getMiningCoreMinerDataTls,
  getMiningCorePoolsTls,
} from "./miningCoreTls";

import {
  getMiningCoreCardsXel,
  getMiningCoreDashboardDataXel,
  getMiningCoreMinerDataXel,
  getMiningCorePoolsXel,
} from "./miningCoreXel";

import { customCards } from "./custom";

/*import {
  getDynexCards,
  getDynexDashboardData,
  getDynexMinerData,
} from "./joinDynex"; 

/*import {
  getChinetCards,
  getChinetDashboardData,
  getChinetMinerData,
} from "./joinChinet"; */

/* import {
  getNovasolCards,
  getNovasolDashboardData,
  getNovasolMinerData,
} from "./joinNovasol"; */

//Função responsavel por criar os cards da LandingPage(Home). 
export const cards = async () => {
  let cards = [];

  const add = (res) => cards.push(res);
  /*
    try {
      await getFoundationV1Cards().then(add);
    } catch {
      console.log("As pools da FoundationV1 não estão disponíveis no momento");
    }*/
  
    try {
      await getFoundationV2Cards().then(add);
    } catch {
      console.log("As pools da FoundationV2 não estão disponíveis no momento");
    } 

  try {
    await getMiningCoreCards().then(add);
  } catch {
    console.log("As pools da Mining Core não estão disponíveis no momento");
  }

  try {
    await getMiningCoreCardsKas().then(add);
  } catch {
    console.log("As pools da Mining Core não estão disponíveis no momento");
  }

  try {
    await getMiningCoreCardsTls().then(add);
  } catch {
    console.log("As pools da Mining Core não estão disponíveis no momento");
  }

  try {
    await getMiningCoreCardsXel().then(add);
  } catch {
    console.log("As pools da teste não estão disponíveis no momento");
  }

  try {
    await getXdagCards().then(add)
  } catch (err) {
    console.log("Xdag não está disponível no momento")
  }

  if (customCards.length) {
    cards = [...cards, ...customCards]
  }

  /* try {
    await getDynexCards().then(add);
  } catch {
    console.log("A pool Dynex não esta disponível no momento");
  } */

  /*try {
    await getNovasolCards().then(add);
  } catch {
    console.log("A pool NovaSol não esta disponível no momento");
  } */

  /* try {
    await getChinetCards().then(add);
  } catch {
    console.log("A pool Chinet não esta disponível no momento");
  } */

  return cards;
};

//Função responsável por criar a pagina das pool
export const pool = async (poolName) => {

  /* try {
    if (poolName === "dynex") {
      return await getDynexDashboardData();
    }
  } catch (err) {
    console.log(err);
  }

  try {
    if (poolName === "chinet") {
      return await getChinetDashboardData();
    }
  } catch (err) {
    console.log(err);
  }

  try {
    if (poolName === "novasol") {
      return await getNovasolDashboardData();
    }
  } catch (err) {
    console.log(err);
  } 

  try {
    const foundationV1pools = await getFoundationV1Pools();
    if (foundationV1pools.includes(poolName)) {
      return await getFoundationV1DashboardData(poolName);
    }
  } catch (err) {
    console.log(err);
  }*/

   try {
    const foundationV2pools = await getFoundationV2Pools();
    if (foundationV2pools.includes(poolName)) {
      return await getFoundationV2DashboardData(poolName);
    }
  } catch (err) {
    console.log(err);
  }

  try {
    const miningCorePoolsTls = await getMiningCorePoolsTls();
    if (miningCorePoolsTls.includes(poolName)) {
      return await getMiningCoreDashboardDataTls(poolName);
    }
  } catch (err) {
    console.log(err);
  }

  try {
    const miningCorePoolsXel = await getMiningCorePoolsXel();
    if (miningCorePoolsXel.includes(poolName)) {
      return await getMiningCoreDashboardDataXel(poolName);
    }
  } catch (err) {
    console.log(err);
  }

  try {
    const miningCorePools = await getMiningCorePools();
    if (miningCorePools.includes(poolName)) {
      return await getMiningCoreDashboardData(poolName);
    }
  } catch (err) {
    console.log(err);
  }

  try {
    const miningCorePoolsKas = await getMiningCorePoolsKas();
    if (miningCorePoolsKas.includes(poolName)) {
      return await getMiningCoreDashboardDataKas(poolName);
    }
  } catch (err) {
    console.log(err);
  }

  try {
    if (poolName.toLowerCase() === "xdag") {
      return await getXdagDashboardData()
    }
  } catch (err) {
    console.log(err)
  }

  return {};
};

//Função responsável por criar a página do miner
export const miner = async (poolName, address) => {

  /* try {
     if (poolName === "dynex") {
       return await getDynexMinerData(address);
     }
   } catch (err) {
     console.log(err);
   } 
 
   try {
     if (poolName === "chinet") {
       return await getChinetMinerData(address);
     }
   } catch (err) {
     console.log(err);
   }
 
   try {
     if (poolName === "novasol") {
       return await getNovasolMinerData(address);
     }
   } catch (err) {
     console.log(err);
   } 
 
   try {
     const foundationV1pools = await getFoundationV1Pools();
     if (foundationV1pools.includes(poolName)) {
       return await getFoundationV1MinerData(poolName, address);
     }
   } catch (err) {
     console.log(err);
   }*/
 
    try {
     const foundationV2pools = await getFoundationV2Pools();
     if (foundationV2pools.includes(poolName)) {
       return await getFoundationV2MinerData(poolName, address);
     }
   } catch (err) {
     console.log(err);
   } 

  try {
    const miningCorePoolsTls = await getMiningCorePoolsTls();
    if (miningCorePoolsTls.includes(poolName)) {
      return await getMiningCoreMinerDataTls(poolName, address);
    }
  } catch (err) {
    console.log(err);
  }

  try {
    const miningCorePoolsXel = await getMiningCorePoolsXel();
    if (miningCorePoolsXel.includes(poolName)) {
      return await getMiningCoreMinerDataXel(poolName, address);
    }
  } catch (err) {
    console.log(err);
  }

  try {
    const miningCorePools = await getMiningCorePools();
    if (miningCorePools.includes(poolName)) {
      return await getMiningCoreMinerData(poolName, address);
    }
  } catch (err) {
    console.log(err);
  }

  try {
    const miningCorePoolsKas = await getMiningCorePoolsKas();
    if (miningCorePoolsKas.includes(poolName)) {
      return await getMiningCoreMinerDataKas(poolName, address);
    }
  } catch (err) {
    console.log(err);
  }

  try {
    if (poolName.toLowerCase() === "xdag") {
      return await getXdagMinerData(address)
    }
  } catch (err) {
    console.log(err)
  }

  return {};
};
