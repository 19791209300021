import { FooterStyled } from "./footerStyle";
import { useTranslation } from "react-i18next";
import { IoLogoDiscord } from "react-icons/io5";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterStyled>
      <p className="contact">support@threepool.tech</p>
     
     {<a href="https://discord.gg/HK3KbPKUDF" target="_blank" rel="noreferrer">
        <IoLogoDiscord />
        Discord Oficial
      </a>}
     
      <p>{t("landingPage.section_footer.copyright")}</p>

    
    </FooterStyled>
  );
};
