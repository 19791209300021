import { HashLink } from "react-router-hash-link"
import Logo from "../../assets/logo.png"
import { LiPools } from "./cardStyle"
import { useTranslation } from "react-i18next"

export const CustomCard = ({ coin }) => {
    const { t } = useTranslation();

    return (
        <LiPools>
            <HashLink target="blank" to={coin.link}>
                <div className="headerDivLi">
                    <div className="containerPool">
                        <img
                            src={coin.logo !== "" ? coin.logo : Logo}
                            alt="cripto"
                        />
                        <div className="namePool">
                            <h3>{coin.name.toUpperCase()}</h3>
                            <span>{coin.coin}</span>
                        </div>
                    </div>
                </div>
                <ul className="buttonContainer">
                <button>{t("landingPage.sectionCardPools.cards.conect")}</button>
                </ul>
            </HashLink>
        </LiPools >
    )
}