import axios from "axios"
import { convertHashrate } from "./utils"

const baseUrl = axios.create({
    baseURL: "https://xdag.threepool.tech",
    timeout: 10000
})

const apiBase = axios.create({
    baseURL: "https://explorer.xdag.io/api",
    timeout: 10000
})

export const getXdagPoolHashrate = async () => {
    const res = await baseUrl.post("/api", {
        "jsonrpc": "2.0",
        "method": "xdag_poolHashrate",
        "params": [],
        "id": 1
    })

    return res.data.result
}

export const getXdagPoolConfig = async () => {
    const res = await baseUrl.post("/api", {
        "jsonrpc": "2.0",
        "method": "xdag_poolConfig",
        "params": [],
        "id": 1
    })
    
    return res.data.result
}

/* export const getXdagPoolWorkers = async () => {
    const res = await baseUrl.post("/api", {
        jsonrpc: "2.0",
        method: "xdag_getPoolWorkers",
        params: [],
        id: 1
    })

    return res.data.result
} */

    export const getXdagPoolWorkers = async () => {
        const res = await baseUrl.post("/api", {
            jsonrpc: "2.0",
            method: "xdag_getPoolWorkers",
            params: [],
            id: 1
        })
    
        const result = res.data.result
    
        const walletIndex = result.findIndex((elt) => elt.address === "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
        if (walletIndex !== undefined) {
            result.splice(walletIndex, 1)
        }
    
        return result
    }

export const getXdagMinerAccount = async (wallet) => {
    const res = await baseUrl.post("/api", {
        jsonrpc: "2.0",
        method: "xdag_minerAccount",
        params: [wallet],
        id: 1
    })

    return res.data.result
}

export const getXdagMinerHashrate = async (wallet) => {
    const res = await baseUrl.post("/api", {
        jsonrpc: "2.0",
        method: "xdag_minerHashrate",
        params: [wallet],
        id: 1
    })

    return res.data.result
}

export const getBaseXdagStatus = async () => {
    const res = await apiBase.get("/status")

    return res.data
}

export const getBaseXdagBlocks = async () => {
    const res = await apiBase.get("/last-blocks")

    return res.data.blocks
}

/* const joinXdagCardInfoObj = (poolHashrate, poolConfig) => {
    return {
        id: "xdag",
        name: "Xdag",
        symbol: "xdag",
        hashrate: convertHashrate(poolHashrate.hashrate),
        activeMiners: poolHashrate.total_online,
        luck: 0,
        fee: poolConfig.poolFeeRation + "%",
        paymentInterval: "2h",
        minPayment: 3 + " XDAG",
        payoutScheme: "",
        from: "xdag"
    }
} */

    const joinXdagCardInfoObj = (poolHashrate, poolConfig, poolWorkers) => {
        return {
            id: "xdag",
            name: "Xdag",
            symbol: "xdag",
            hashrate: convertHashrate(poolHashrate.hashrate),
            activeMiners: poolWorkers.length,
            luck: 0,
            fee: poolConfig.poolFeeRation + "%",
            paymentInterval: "2h",
            minPayment: 3 + " XDAG",
            payoutScheme: "",
            from: "xdag"
        }
    }


/* const joinXdagPoolInfoObj = (poolHashrate, poolConfig, poolWorkers) => {
    return {
        headers: {
            fee: poolConfig.poolFeeRation + "%",
            activeWorkers: poolHashrate.total_online,
            totalWorkers: poolHashrate.total,
            hashrate: convertHashrate(poolHashrate.hashrate),
            algorithm: "RandomX"
        },
        miners: poolWorkers.map(({ address, hashrate }) => ({
            wallet: address,
            hashrate: convertHashrate(hashrate)
        }))
    }
} */

    const joinXdagPoolInfoObj = (poolHashrate, poolConfig, poolWorkers) => {
        return {
            headers: {
                fee: poolConfig.poolFeeRation + "%",
                activeWorkers: poolHashrate.total_online,
                totalMiners: poolWorkers.length,
                hashrate: convertHashrate(poolHashrate.hashrate),
                algorithm: "RandomX"
            },
            miners: poolWorkers.map(({ address, hashrate }) => ({
                wallet: address,
                hashrate: convertHashrate(hashrate)
            }))
        }
    }

const joinXdagMinerInfoObj = (wallet, minerAccount, minerHashrate) => {
    return {
        headers: {
            wallet,
            hashrate: convertHashrate(minerHashrate.total_hashrate),
            todayHashrate: convertHashrate(minerHashrate.total_hashrate24h),
            payment: minerAccount.total_payment,
            pendingPayment: minerAccount.total_unpaid,
            workersOnline: minerHashrate.total_online,
        },
        workers: minerHashrate.hashrate.map((worker) => ({
            worker: worker.name,
            hashrate: convertHashrate(worker.hashrate),
            validShares: worker.validShares
        }))
    }
}
/* 
export const getXdagCards = async () => {
    const poolHashrate = await getXdagPoolHashrate()
    const poolConfig = await getXdagPoolConfig()

    const xdagInfo = joinXdagCardInfoObj(poolHashrate, poolConfig)

    return xdagInfo
} */

    export const getXdagCards = async () => {
        const poolHashrate = await getXdagPoolHashrate()
        const poolConfig = await getXdagPoolConfig()
        const poolWorkers = await getXdagPoolWorkers()
    
        const xdagInfo = joinXdagCardInfoObj(poolHashrate, poolConfig, poolWorkers)
    
        return xdagInfo
    }

export const getXdagDashboardData = async () => {
    const poolHashrate = await getXdagPoolHashrate()
    const poolConfig = await getXdagPoolConfig()
    const poolWorkers = await getXdagPoolWorkers()

    const formatedData = joinXdagPoolInfoObj(poolHashrate, poolConfig, poolWorkers)

    return formatedData
}

export const getXdagMinerData = async (address) => {
    const minerAccount = await getXdagMinerAccount(address)
    const minerHashrate = await getXdagMinerHashrate(address)

    const formatedData = joinXdagMinerInfoObj(address, minerAccount, minerHashrate)

    return formatedData
}
