import {
  Contacts,
  ContainerSectionFooter,
  FAQSection,
  Footer,
  Section,
} from "./sectionFooterStyle";
import { AiOutlineMail } from "react-icons/ai";
import { IoLogoDiscord } from "react-icons/io5";
import { FAQContainer } from "../../../components/FAQContainer/faqContainer";
import { useState } from "react";
import { SectionServerInfo } from "./ServerInfo/sectionServerInfo";
import { useTranslation } from "react-i18next";

export const SectionFooter = () => {
  const [FAQ, setFAQ] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <ContainerSectionFooter id="server">
        <Section className="container">
          <h2>{t("landingPage.section_footer.title")}</h2>
          <SectionServerInfo />
        </Section>
      </ContainerSectionFooter>
      <Footer>
        <FAQSection id="FAQ">
          <button onClick={() => setFAQ(!FAQ)}>
            {t("landingPage.section_footer.faq_button")}
          </button>
          {FAQ && <FAQContainer />}
        </FAQSection>
        <Contacts>
    {      <a
            href="https://discord.gg/HK3KbPKUDF" 
            target="_blank"
            rel="noreferrer"
          >
            <IoLogoDiscord />
            Discord Oficial
          </a>}
          <p>
            <AiOutlineMail />
            support@threepool.tech
          </p>
          <br></br>
          <p>{t("landingPage.section_footer.copyright")}</p>
        </Contacts>
      </Footer>
    </>
  );
};
