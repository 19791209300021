import styled from "styled-components";

export const LiPools = styled.li`
  width: 378px;
  max-width: 100%;
  min-height: 237px;
  background-color: var(--pools-bg);
  border-radius: 6px;
  box-shadow: 0px 0px 8px -1px var(--gray-5);
  cursor: pointer;

  & > a {
    display: block;
    height: 237px;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      border-radius: 4px;
      background-color: var(--brand-1);
      padding: 8px 0px;
      width: 100%;
      transition: 0.3s;
      font-size: var(--f-size-2-title);
      color: var(--gray-5);
      font-weight: var(--font-weigth-1);
    }

    button:hover {
      background-color: #ffffff;
      color: var(--gray-1)
    }
  }

  .headerDivLi {
    width: 100%;
    height: 65px;
    background-color: #21262c;
    padding: 8px 12px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .containerPool {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      img {
        width: 50px;
        height: 50px;
        cursor: pointer;
      }

      .namePool {
        display: flex;
        flex-direction: column;
        gap: 4px;

        h3 {
          font-size: var(--f-size-2-title);
          color: var(--brand-1);
          font-weight: var(--font-weigth-1);
          cursor: pointer;
          white-space: nowrap;
        }
        span {
          font-size: var();
          color: #ffffff;
          font-weight: var(--font-weigth-4);
          cursor: pointer;
        }
      }
    }

    p {
      color: var(--brand-1);
      font-size: var(--f-size-2-title);
      font-weight: var(--font-weigth-3);
      text-align: right;
      cursor: pointer;
    }
  }

  ul {
    height: 73%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: space-between;
    padding: 16px;
  }
`;
