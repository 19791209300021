export const foundationV2 = [
  {
    name: "clore",
    algorithm: "algorithm",
    symbol: "CLORE",
    fee: "0.5%",
    paymentInterval: "1h",
    minPayment: "1 CLORE",
  },
  {
    name: "litoreum",
    algorithm: "algorithm",
    symbol: "LTRM",
    fee: "0.5%",
    paymentInterval: "1h",
    minPayment: "1 LTRM",
  },
  {
    name: "bitfish",
    algorithm: "algorithm",
    symbol: "BTSC",
    fee: "1%",
    paymentInterval: "1h",
    minPayment: "1 BTSC",
  },
  {
    name: "paprika",
    algorithm: "algorithm",
    symbol: "PAPRY",
    fee: "1%",
    paymentInterval: "1h",
    minPayment: "1 PAPRY",
  },
  {
    name: "raptoreum",
    algorithm: "algorithm",
    symbol: "RTM",
    fee: "1%",
    paymentInterval: "1h",
    minPayment: "1 RTM",
  },
  {
    name: "evrmore",
    algorithm: "evrprogpow",
    symbol: "EVR",
    fee: "1%",
    paymentInterval: "2h",
    minPayment: "1 EVR",
  },
];
