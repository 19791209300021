import axios from "axios";
import { convertDate, convertHashrate, getHashrateDivisor } from "./utils";

import { foundationV2 } from "../FoundationV2DataBase/foundationV2DataBase";

// Cria a base url para fazermos as requisições nas outras funções.
const baseUrl = axios.create({
  baseURL: "https://evr.threepool.tech:4545/api/v2/",
  timeout: 10000,
});

// Lista o nome das pools.
export const getFoundationV2Pools = async () => {
  const res = await baseUrl.get("/pools");

  return res.data.body;
};

// Busca informações gerais de uma pool.
const getFoundationV2Info = async (name) => {
  const res = await baseUrl.get(`/${name}`);

  return res.data.body;
};

const getFoundationV2Network = async (name) => {
  const res = await baseUrl.get(`/${name}/current/network`);

  return res.data.body;
};

// Busca informações referente aos blocos de uma pool.
const getFoundationV2PendingBlocks = async (name) => {
  const res = await baseUrl.get(`/${name}/current/blocks`);

  return res.data.body;
};

// Busca informações referente aos blocos de uma pool.
const getFoundationV2ConfirmedBlocks = async (name) => {
  const res = await baseUrl.get(`/${name}/historical/blocks`);

  return res.data.body;
};

// Busca informações referente ao pagamento de uma pool.
const getFoundationV2Payments = async (name) => {
  const res = await baseUrl.get(`/${name}/historical/payments`);

  return res.data.body;
};

// Busca informações referentes aos mineradores de uma pool e formata em uma lista (pois vem como outro tipo de dado da API).
const getFoundationV2Miners = async (poolName) => {
  const res = await baseUrl.get(`/${poolName}/current/miners`);

  return res.data.body;
};

// Busca as informações gerais de um minerador.
const getFoundationV2MinerInfo = async (name, miner) => {
  const res = await baseUrl.get(`/${name}/current/miners?miner=${miner}`);

  return res.data.body[0];
};

// Busca as informações dos workers de um minerador
const getFoundationV2MinerWorkers = async (name, miner) => {
  const res = await baseUrl.get(`/${name}/current/workers?miner=${miner}`);

  return res.data.body;
};

// Busca as informações para gerar o gráfico
const getFoundationV2Charts = async (name) => {
  const res = await baseUrl.get(`${name}/historical/metadata?limit=24`);

  return res.data.body;
};

// Função que recebe dados e transforma no Card exibido na landing page.
const joinFoundationV2CardInfoObj = (name, data) => {
  const info = foundationV2.find((elt) => elt.name === name);

  return {
    id: name,
    name,
    symbol: info.symbol,
    hashrate: convertHashrate(data.hashrate),
    activeMiners: data.miners,
    luck: data.effort.toFixed(2) + "%",
    fee: info.fee,
    paymentInterval: info.paymentInterval,
    minPayment: info.minPayment,
    from: "foundationV2",
  };
};

// Função que recebe dados e transforma em um bloco que dados a serem usados na dashboard.
const joinFoundationV2PoolInfoObj = (
  name,
  data,
  net,
  blocks,
  payments,
  miners,
  charts
) => {
  blocks.sort((blockA, blockB) => blockA.height - blockB.height).reverse()

  const info = foundationV2.find((elt) => elt.name === name);
  const firstBlock = blocks.find((block) => block.confirmations !== 0);
  const first10Blocks = blocks.filter(
    (block, i) => block.confirmations !== 0 && i < 10
  );
  const first100Blocks = blocks.filter(
    (block) => block.confirmations !== 0
  );

  const luck = firstBlock ? (firstBlock.difficulty || 0).toFixed(2) + "%" : "0%";
  const luck10 = first10Blocks?.length
    ? (first10Blocks?.reduce((acc, act) => acc + act.difficulty, 0) || 0).toFixed(
        2
      ) + "%"
    : "0%";
  const luck100 = first100Blocks?.length
    ? (first100Blocks?.reduce((acc, act) => acc + act.difficulty, 0) || 0).toFixed(
        2
      ) + "%"
    : "0%";

  const filteredBlocks = blocks?.filter(
    (block) => block.category.includes("generate") || block.category === "immature"
  );

  const formatedBlocks = filteredBlocks.map((block) => ({
    height: block.height,
    category: block.category,
    hash: block.hash,
    type: "",
    wallet: block.miner,
    date: convertDate(+block.timestamp),
    luck: block.luck,
    reward: block.reward.toFixed(2),
  }));

  const divisor = getHashrateDivisor(charts[charts.length - 1].hashrate);

  return {
    headers: {
      minPayment: info.minPayment,
      totalPaid: payments.length,
      fee: info.fee,
      netDiff: convertHashrate(net.difficulty),
      activeMiners: data.miners,
      foundBlocks: formatedBlocks.length,
      hashrate: convertHashrate(data.hashrate),
      algorithm: info.algorithm,
      actualLuck: data.effort,
      luck,
      luck10,
      luck100,
    },
    blocks: {
      confirmed: formatedBlocks.filter(
        (block) => block.category !== "immature"
      ),
      pending: formatedBlocks.filter((block) => block.category === "immature"),
      orphaned: formatedBlocks.filter((block) => block.category !== "immature" && block.category !== "generate")
    },
    payments: payments.map((elt) => ({
      address: elt.transaction,
      reward: `${elt.amount.toFixed(2)} ${info.symbol}`,
    })),
    miners: miners.map(({ miner, hashrate }) => ({
      wallet: miner,
      hashrate: convertHashrate(hashrate),
    })),
    charts: charts.map((elt) => ({
      hashrate: (+elt.hashrate / +divisor).toFixed(2),
      date: elt.timestamp,
    })),
    from: "foundationV2",
  };
};

// Função que recebe dados e transforma em um bloco que dados a serem usados na página do minerador.
const joinFoundationV2MinerInfoObj = (data, workers) => {
  const retrieveWallet = (wallet) => {
    const commaIndex = wallet.indexOf(".");
    return wallet.substring(commaIndex + 1);
  };

  const formatedWorkers = workers.map((elt) => {
    return {
      worker: retrieveWallet(elt.worker),
      hashrate: convertHashrate(elt.hashrate),
    };
  });

  return {
    headers: {
      wallet: data.miner,
      payment: data.paid.toFixed(2),
      hashrate: convertHashrate(data.hashrate),
      effort: data.effort.toFixed(2) + "%",
      validShares: +data.valid,
      pendingPayout: data.balance.toFixed(2),
      invalidShares: data.invalid,
      staleShares: data.stale,
    },
    workers: formatedWorkers,
    from: "foundationV2",
  };
};

// Função responsável por criar os cards das pools na landing page.
export const getFoundationV2Cards = async () => {
  const data = [];

  await getFoundationV2Pools().then((res) => {
    res.forEach(async (pool) => {
      await getFoundationV2Info(pool).then((resP) => {
        const card = joinFoundationV2CardInfoObj(pool, resP[0]);
        data.push(card);
      });
    });
  });

  return data;
};

export const getFoundationV2DashboardData = async (poolName) => {
  const data = await getFoundationV2Info(poolName);
  const net = await getFoundationV2Network(poolName);
  const pendingBlocks = await getFoundationV2PendingBlocks(poolName);
  const confirmedBlocks = await getFoundationV2ConfirmedBlocks(poolName);
  const blocks = [...pendingBlocks, ...confirmedBlocks];
  const payments = await getFoundationV2Payments(poolName);
  const miners = await getFoundationV2Miners(poolName);
  const charts = await getFoundationV2Charts(poolName);

  const formatedData = joinFoundationV2PoolInfoObj(
    poolName,
    data[0],
    net[0],
    blocks,
    payments,
    miners,
    charts
  );

  return formatedData;
};

export const getFoundationV2MinerData = async (poolName, address) => {
  const data = await getFoundationV2MinerInfo(poolName, address);
  const workers = await getFoundationV2MinerWorkers(poolName, address);

  const formatedData = joinFoundationV2MinerInfoObj(data, workers);

  return formatedData;
};
