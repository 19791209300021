import { BlocksContainer, InfoCell, LoadingProgress } from "./blocksInfosStyle";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { dataBase } from "../../../services/BlockLinks/blocksDB";
import { useTranslation } from "react-i18next";

export const BlocksInfos = ({ blocks, from }) => {
  const [statusBlock, setStatusBlock] = useState("confirmed");

  const { t } = useTranslation();
  const { name } = useParams();

 // console.log(blocks)

  const infoBlocks = statusBlock === "confirmed" ? blocks?.confirmed : statusBlock === "pending" ? blocks?.pending : blocks?.orphaned;
    
  return (
    <BlocksContainer>
      <h1>{t("dash.main.container.title")}</h1>
      <ul>
        <li>
          <button
            className={statusBlock === "confirmed" ? "selected" : ""}
            onClick={() => setStatusBlock("confirmed")}
          >
            {t("dash.main.container.buttons.confirmed")}
          </button>
        </li>
        <li>
          <button
            className={statusBlock === "pending" ? "selected" : ""}
            onClick={() => setStatusBlock("pending")}
          >
            {t("dash.main.container.buttons.pending")}
          </button>
        </li>
        <li>
          <button
            className={statusBlock === "orphaned" ? "selected" : ""}
            onClick={() => setStatusBlock("orphaned")}
          >
            {t("dash.main.container.buttons.orphaned")}
          </button>
        </li>
      </ul>
      <InfoCell id={"tagTitle"}>
        <p className="tagSmall">{t("dash.main.container.table.number")}</p>
        <p className="tagLarge">{t("dash.main.container.table.block")}</p>
        <p className="tagMedium">{t("dash.main.container.table.miner")}</p>
        <p className="tagMedium">{t("dash.main.container.table.time")}</p>
        <p className="tagSmall">{t("dash.main.container.table.effort")}</p>
        <p className="tagMedium">
          {statusBlock
            ? t("dash.main.container.table.reward")
            : t("dash.main.container.table.progress")}
        </p>
      </InfoCell>
      {infoBlocks?.length === 0 ? (
        <h3>{t("dash.blocks_warning")}</h3>
      ) : (
        infoBlocks?.map((info, i) => {
          if (from === "xdag") {
            return (
              <InfoCell
                key={i}
                target="_blank"
                rel="noreferrer"
                disabled={name === "dynex" || name === "chinet" || name === "novasol" || name === "xdag"}
              >
              <span className="tagSmall">
                <p className="tagDesc">
                  {t("dash.main.container.table.number")}
                </p>
                <p className={"tagName"}>
                  {t("dash.main.container.table.number")}
                </p>
                <p>{info.height}</p>
              </span>
              <span className="tagMedium">
                <p className="tagDesc">
                  {t("dash.main.container.table.miner")}
                </p>
                <p className={"tagName"}>
                  {t("dash.main.container.table.miner")}
                </p>
                <p>
                  {info.wallet?.slice(0, 4) || "****"}****
                  {info.wallet?.slice(-4) || "****"}
                </p>
              </span>
              <span className="tagMedium">
                <p className="tagDesc">{t("dash.main.container.table.time")}</p>
                <p className={"tagName"}>
                  {t("dash.main.container.table.time")}
                </p>
                <p>{info?.date}</p>
              </span>
            </InfoCell>
          );
          }
          const link =
            from === "foundationV1"
              ? `${dataBase.find((elt) => elt.name === name)?.link}${
                  name === "evrmore" ? info.height : info.hash
                }`
              : from === "dynex" || from === "chinet" || from === "novasol"
              ? null
              : info.link;

          return (
            <InfoCell
              key={i}
              target="_blank"
              rel="noreferrer"
              disabled={name === "dynex" || name === "chinet" || name === "novasol"}
              href={link}
            >
              <span className="tagSmall">
                <p className="tagDesc">
                  {t("dash.main.container.table.number")}
                </p>
                <p className={"tagName"}>
                  {t("dash.main.container.table.number")}
                </p>
                <p>{info.height}</p>
              </span>
              <span className="tagLarge">
                <p className="tagDesc">
                  {t("dash.main.container.table.block")}
                </p>
                <p className={"tagName"}>
                  {t("dash.main.container.table.block")}
                </p>
                <p>
                  {info.hash?.slice(0, 7) || "*******"}****
                  {info.hash?.slice(-7) || "*******"}
                </p>
              </span>
              <span className="tagMedium">
                <p className="tagDesc">
                  {t("dash.main.container.table.miner")}
                </p>
                <p className={"tagName"}>
                  {t("dash.main.container.table.miner")}
                </p>
                <p>
                  {info.wallet?.slice(0, 4) || "****"}****
                  {info.wallet?.slice(-4) || "****"}
                </p>
              </span>
              <span className="tagMedium">
                <p className="tagDesc">{t("dash.main.container.table.time")}</p>
                <p className={"tagName"}>
                  {t("dash.main.container.table.time")}
                </p>
                <p>{info?.date}</p>
              </span>
              <span className="tagSmall">
                <p className="tagDesc">
                  {t("dash.main.container.table.effort")}
                </p>
                <p className={"tagName"}>
                  {t("dash.main.container.table.effort")}
                </p>
                <p id="effort">{info.luck}</p>
              </span>
              <span className="tagMedium">
                <p className="tagDesc">
                  {t("dash.main.container.table.reward")}
                </p>
                <p className={"tagName"}>
                  {t("dash.main.container.table.reward")}
                </p>
                {statusBlock ? (
                  <p id="reward">{info.reward}</p>
                ) : from !== "foundationV1" ? (
                  <LoadingProgress progress={(info.progress * 100).toFixed(1)}>
                    <span className="progress " />
                    <p id="reward">{(info.progress * 100).toFixed(1)} %</p>
                  </LoadingProgress>
                ) : (
                  <div>
                    <p>{t("dash.main.container.table.waiting")}</p>
                  </div>
                )}
              </span>
            </InfoCell>
          );
        })
      )}
    </BlocksContainer>
  );
};
